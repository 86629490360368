import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { PageHeaderWithBreadcrumbs } from "../components/pageHeaderWithBreadcrumbs";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const AboutUs: React.FC<PageProps<DataProps>> = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout
      seoTitle={t("aboutUs.title")}
      seoDescription={t("aboutUs.pageTitle")}
      location={location}
    >
      <div className="container page-about">
        <PageHeaderWithBreadcrumbs
          currentBreadcrumbText={t("aboutUs.title")}
          title={t("aboutUs.pageTitle")}
        />
        <div className="row mb-5">
          <div className="col-md-6 mb-4 mb-md-0 pr-lg-0">
            <h2 className="about-section-one-title">
              {t("aboutUs.headlinePart1")}{" "}
              <span className="font-weight-normal">
                {t("aboutUs.headlinePart2")}
              </span>
            </h2>
            <div className="about-section-one-content">
              <p>{t("aboutUs.underHeadline")}</p>
            </div>
          </div>
          <div className="col-md-6 pl-lg-0 d-flex align-items-center align-items-lg-end">
            <img
              src="/images/about-us/main-photo.jpg"
              alt="about"
              className="img-fluid"
              width="448px"
            />
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-8 offset-md-2">
            <blockquote>
              <p>{t("aboutUs.quote")}</p>
            </blockquote>
          </div>
        </div>
        <section className="ins-page-section">
          <h5 className="text-center font-os mb-0">
            {t("aboutUs.avatarsHeaderPart1")}
          </h5>
          <h2 className="team-section-title">
            {t("aboutUs.avatarsHeaderPart2")}
          </h2>
          <div className="row">
            <div className="col-md-6 mb-5">
              <div className="team-member-card">
                <img
                  src="/images/about-us/pawel.png"
                  alt="Paweł Konowoł"
                  className="team-member-avatar"
                />
                <h6 className="mb-0">Paweł Konowoł</h6>
                <p className="team-member-designation">CEO</p>
                <p>
                  <p className="mb-3">{t("aboutUs.pawelQuote")}</p>
                  <div className="mb-5">
                    <a
                      href="https://www.linkedin.com/in/pawelkonowol/"
                      className="text-primary mb-5"
                      target="_blank"
                    >
                      <img
                        alt="LinkedIn"
                        src="/images/linkedin-blue.png"
                        style={{ width: 24 }}
                      />
                    </a>
                  </div>
                </p>
              </div>
            </div>
            <div className="col-md-6 mb-5">
              <div className="team-member-card">
                <img
                  src="/images/about-us/bartek.png"
                  alt="Bartłomiej Maćkiewicz"
                  className="team-member-avatar"
                />
                <h6 className="mb-0">Bartłomiej Maćkiewicz</h6>
                <p className="team-member-designation">COO</p>
                <p>
                  <p className="mb-3">{t("aboutUs.bartekQuote")}</p>
                  <div className="mb-5">
                    <a
                      href="https://www.linkedin.com/in/bart%C5%82omiej-ma%C4%87kiewicz-20256757/"
                      className="text-primary mb-5"
                      target="_blank"
                    >
                      <img
                        alt="LinkedIn"
                        src="/images/linkedin-blue.png"
                        style={{ width: 24 }}
                      />
                    </a>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="row mb-5">
          <div className="col-md-6 mb-4 mb-md-0 pr-lg-0">
            <h2 className="about-section-one-title">
              {t("aboutUs.innovationSection.headlinePart1")}{" "}
              <span className="font-weight-normal">
                {t("aboutUs.innovationSection.headlinePart2")}
              </span>
            </h2>
            <div className="about-section-one-content">
              <p>{t("aboutUs.innovationSection.description")}</p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src="/images/about-us/flota-parking.png"
              alt="about"
              className="img-fluid rounded-lg mb-4"
            />
            <h5 className="mb-4">
              {t("aboutUs.innovationSection.postDescription")}
            </h5>
            <a
              className="btn btn-outline-primary btn-rounded"
              href={t("aboutUs.innovationSection.moreButtonUrl")}
            >
              {t("aboutUs.innovationSection.moreButton")}
            </a>
          </div>
        </div>
        <section className="ins-page-section">
          <div className="row mb-5">
            <div className="col-sm-12">
              <h4 className="section-title mb-4 text-center">
                {t("aboutUs.innovationSection.afterHoursHeader")}
              </h4>
            </div>
            <div className="col-md-6">
              <img
                src="/images/about-us/after-hours-4.jpg"
                alt="blog details"
                className="img-fluid rounded-lg mb-3"
              />
              <img
                src="/images/about-us/after-hours-3.jpg"
                alt="blog details"
                className="img-fluid rounded-lg mb-3"
              />
            </div>
            <div className="col-md-6">
              <img
                src="/images/about-us/after-hours-1.jpg"
                alt="blog details"
                className="img-fluid rounded-lg mb-3"
              />
              <img
                src="/images/about-us/after-hours-2.jpg"
                alt="blog details"
                className="img-fluid rounded-lg mb-3"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
